import React from 'react'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

import { s } from 'shared/Button/_button'

interface Button {
  to: string
  classname: string
  children: any
}

const AnchorButton = ({ to, classname, children }: Button) => {
  return (
    <AnchorLink to={to} stripHash className={`${s.button} ${classname}`}>
      {children}
    </AnchorLink>
  )
}

export default AnchorButton
