import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import { AnimationTrail } from 'shared/Animation/Animation'
import Layout from 'shared/Layout/Layout'
import Seo from 'shared/Seo/Seo'
import AnchorButton from 'shared/Button/AnchorButton'

const s = {
  container: `w-full h-screen`,
  wrapper: `max-w-screen-wrapper w-11/12 mx-auto`,

  content: `absolute top-2/4 -translate-y-2/4 z-2 w-w77 h-fit-content text-white 
  smm:mt-6 mdm:w-w50 smx:w-w32`,
  title: `mb-20 text-h1`,
  description: `mb-0 text-clamp-3xl`,

  image: `absolute top-0 w-full h-full`,
  overlay: `absolute top-0 left-0 z-1 w-full h-full bg-opblack`,
}

const NotFoundPage = () => {
  const datas = useStaticQuery(graphql`
    query {
      contentfulError {
        slug
        title
        subtitle
        link
        text
        images {
          title
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 80
            placeholder: NONE
            formats: [AUTO, WEBP]
          )
        }
      }
    }
  `)

  const { title, subtitle, link, text, images } = datas.contentfulError

  return (
    <Layout backgrounds="transparent">
      <Seo title={title} description={subtitle} />
      <section className={s.container}>
        <GatsbyImage
          className={s.image}
          image={images.gatsbyImageData}
          alt={images.title}
        />
        <div className={s.overlay} />
        <div className={`${s.wrapper}`}>
          <div className={s.content}>
            <AnimationTrail delay={300} x={0} y={100}>
              <p className={s.description}>{subtitle}</p>
              <h1 className={s.title}>{title}</h1>

              <AnchorButton to={link} classname="">
                {text}
              </AnchorButton>
            </AnimationTrail>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default NotFoundPage
